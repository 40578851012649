

import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import TebligatList from "@/components/anakart/tebligat/TebligatList.vue";
import HacizList from "@/components/lists/haciz/HacizList.vue";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {TakipDurumu} from "@/enum/TakipDurumu";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import NotList from "@/components/lists/NotList.vue";
import {NotTuru} from "@/enum/NotTuru";
import {ProgramPaths} from "@/enum/ProgramPaths";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {BorcluVekiliEntity} from "@/entity/BorcluVekiliEntity";
import BorcluVekiliForm from "@/components/forms/BorcluVekiliForm.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import {TebligatDurumu} from "@/enum/TebligatDurumu";
import {DavaSonucu} from "@/enum/DavaSonucu";
import UyapEvrakGonderForm from "@/components/forms/UyapEvrakGonderForm.vue";


@Component({
  components: {
    UyapEvrakGonderForm,
    FormWrapper,
    BorcluVekiliForm,
    NotList,
    FormDialog,
    DeleteDialog,
    TebligatList,
    HacizList
  }
})

export default class BorcluCardInfo extends Vue {
  @Prop() borclu!: BorcluEntity;
  @Prop() borcluVekili!: BorcluVekiliEntity;
  @Prop() takipDurumu!: TakipDurumu;
  @Prop() takipId !: number;
  @Prop() vekiller !: Array<BorcluVekiliEntity>;
  @Ref('notList') readonly notList!: NotList;

  TakipDurumu = TakipDurumu;
  notTuru = NotTuru;
  isTakipTipiRehin = false;
  show = false;
  selected = 0;

  headers = [
    {text: "Sabit Telefon", value: "deger"},
    {text: "Mobil Telefon", value: "deger"},
    {text: "E-posta", value: "deger"},
    {text: "Web Sitesi", value: "deger"},
    {text: "Faks", value: "deger"},
    {text: "Sosyal Medya", value: "deger"}
  ];

  get vergiTcKimlikNo() {
    if (this.borclu.kurum_id && this.borclu.kurum.vergi_no) {
      return this.$helper.tcknOrVergiNo(this.borclu)
    } else if (this.borclu.kisi_id && this.borclu.kisi.tc_kimlik_no) {
      return this.$helper.tcknOrVergiNo(this.borclu)
    } else if (this.borclu.kurum_id && !this.borclu.kurum.vergi_no) {
      return "Vergi Kimlik No Girilmemiş"
    } else if (this.borclu.kisi_id && !this.borclu.kisi.tc_kimlik_no) {
      return "Tc Kimlik No Girilmemiş"
    }
  }

  get tebligatIconColor() {
    if (this.borclu.tebligatlar && this.borclu.tebligatlar.length > 0) {
      let tebligat = this.borclu.tebligatlar[this.borclu.tebligatlar.length - 1];
      if (tebligat.tebligat_durumu == TebligatDurumu.bila_oldu) return '#d26363';
      else if (tebligat.tebligat_durumu == TebligatDurumu.teblig_edildi) {
        if (!tebligat.itiraz_var_mi) return '#63d269';
        else return '#e5dd5a';
      } else return 'white';
    } else return 'white';
  }

  get showHaciz() {
    if (!this.isTakipTipiRehin && this.borclu.takip.takip_durumu === TakipDurumu.acik && this.borclu.tebligatlar && this.borclu.tebligatlar.length > 0) {
      // Tebliğ edildi ve itiraz edilmedi ise veya itirazın iptali/kaldırılması davasında  kabul veya kısmi kabul olur ise haciz butonu öyle gözükecek.
      let tebligat = this.borclu.tebligatlar[this.borclu.tebligatlar.length - 1];
      if (tebligat.tebligat_durumu == TebligatDurumu.teblig_edildi && !tebligat.itiraz_var_mi)
        return true;
      else if (tebligat.tebligat_durumu == TebligatDurumu.teblig_edildi && tebligat.itiraz_var_mi && (tebligat.itirazin_iptali_davasi[0]?.dava_sonucu == DavaSonucu.kabul || tebligat.itirazin_iptali_davasi[0]?.dava_sonucu == DavaSonucu.kismi_kabul))
        return true;
      else return false;
    } else return false;
  }

  get kisiKurumId() {
    if (this.borclu.kisi_id) {
      return this.borclu.kisi.uyap_kisi_kurum_id;
    } else {
      return this.borclu.kurum.uyap_kisi_kurum_id;
    }
  }

  get evrakGonderTooltip() {
    if (this.$helper.isAuthorized('borclu-evrak-gonder')) {
      if (this.$store.getters.isUyapConnected)
        return 'UYAP Evrak Gönder'
      else
        return 'UYAP Bağlantısını Yapınız'
    } else
      return 'Bu işlem için yetkiniz yok.'
  }

  @Watch('borclu.takip.borclular')
  onBorclularChange() {
    this.notList?.onTakipBorclulariChange();
  }

  @Watch('borclu.takip.alacaklilar')
  onAlacaklilarChange() {
    this.notList?.onTakipAlacaklilariChange();
  }

  mounted() {
    this.takipTipiRehinMi(this.borclu);
  }

  selectedId() {
    if (this.borclu.kisi_id) {
      this.selected = this.borclu.kisi.varsayilan_adres?.id;
      return this.selected;
    } else {
      this.selected = this.borclu.kurum.varsayilan_adres.id;
      return this.selected;
    }
  }

  getPropValue(data: any, key: string): any {
    if (!key.includes('.')) {
      return data[key];
    }
    let props = key.split('.');
    let value = data;
    props.forEach((prop: string) => {
      if (!value || !value.hasOwnProperty(prop)) {
        value = "Bilgi yok";
        return;
      }
      value = value[prop];
    });
    return value;
  }

  detay(item: BorcluEntity) {
    if (item.id ! > 0) {
      this.$router.push(ProgramPaths.icrapro + "/borclu/" + item.id);
    }
  }

  takipTipiRehinMi(item: BorcluEntity) {
    if (item.takip) {
      item.takip.talepler.forEach((talepler: TakipTalebiEntity) => {
        if (talepler.takip_tipi.rehin_mi) {
          this.isTakipTipiRehin = true;
        }
      });
    }
  }

  load() {
    this.$emit('load')
  }

  isAuthorized(keySuffix: string) {
    let ap = this.$store.state.activeProcesses;
    let authKey = null;
    if (keySuffix[0] !== '-') {
      keySuffix = '-' + keySuffix;
    }
    if (ap.filter((item: any) => item.key === "takip-ac").length > 0) {
      authKey = "takip-ac";
    } else if (ap.filter((item: any) => item.key === "takip-duzenle").length > 0) {
      authKey = "borclu" + keySuffix;
    }
    if (!authKey) {
      return false;
    }
    return this.$helper.isAuthorized(authKey)
  }

  onClose() {
    this.show = false;
  }

  onSuccess(data: any) {
    this.onClose();
    this.$emit("success", data);
  }
}
