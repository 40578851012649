
import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import {TakipEntity} from "@/entity/TakipEntity";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import FormWrapper from "@/components/FormWrapper.vue";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";
import {ParaBirimi} from "@/enum/ParaBirimi";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import {AxiosResponse} from "axios";
import {HarcDonemiEntity} from "@/entity/HarcDonemiEntity";
import {HarcTuruEntity} from "@/entity/HarcTuruEntity";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {HarcTuru} from "@/enum/HarcTuru";

@Component({
  components: {FormWrapper},
})
export default class HarcAlacakKalemleriInfo extends Mixins(ObjectInputMixin) {
  @Prop() item!: TakipEntity;
  @Prop() alacakKalemAlaniId!: number;

  HarcTuru = HarcTuru
  AlacakKalemiAlani = AlacakKalemiAlani;
  alacakKalemleri: Array<AlacakKalemiEntity> = [];
  takipToplami: number = 0;

  items: Array<HarcDonemiEntity> = [];
  selectedItem: HarcDonemiEntity = new HarcDonemiEntity();

  toplamTahsilHarciTutari: number = 0;
  odenenTahsilHarciTutari: number = 0;
  odenecekToplamTahsilHarci: number = 0;

  tahsilHarciOranlari=["0.00","2.270","4.550","9.100","11.380"]

  mounted() {
    this.load();
    this.defaultTahsilHarciOrani()
  }

  defaultTahsilHarciOrani(){

    if(this.localValue.tahsil_harci_orani==null){
      this.localValue.tahsil_harci_orani="4.550"
    }
  }
  @Watch('item')
  onItemChange() {
    this.load();
  }

  load() {
    this.getir();
    this.toplamTahsilHarciTutariHesapla();
    this.getHarcDonemi();
  }

  getHarcDonemi() {
    this.$http.get("/api/v1/harc-donemi").then((response: AxiosResponse<Array<HarcDonemiEntity>>) => {
      this.items = response.data;
      this.items.map(item => item.text = item.baslangic_tarihi ? this.$helper.tarihStr(item.baslangic_tarihi) : "");
      this.items.reverse();
      this.selectedItem = this.items[0];
    }).then(()=>this.localValue.tahsil_harci_orani=Number(this.localValue.tahsil_harci_orani).toFixed(3));
  }

  /*get tahsilHarciOranlari() {
    let harclar !: HarcTuruEntity[];
    harclar = this.selectedItem.harclar;
    if (!harclar) {
      return null;
    }
    let harcTurleri = harclar.filter((x: any) => x.harc_turu.harc_tipi === "tahsil")
    let harcOranlari: any = [];
    harcTurleri.forEach((harcTuru) => {
      harcOranlari.push(harcTuru.oran);
      harcOranlari.push("0.00");
    })
    return harcOranlari;
  }*/

  tahsilHarciHesapla() {
    let th = {
      "tahsil_harci": Number(this.localValue.tahsil_harci_orani).toFixed(3)
    }
    this.$http.post("/api/v1/takip/" + this.$route.params.id + "/tahsil-harci", th).then((response) => {
      this.$emit('onSuccess');
    });
    //TODO :: @ELİF EKİMCİ ENDPOİNT EKLEMESİ GEREKLİ
  }

  getir() {
    this.takipToplami = 0;
    this.alacakKalemleri = [];
    this.item.talepler.forEach((takipTalebi: TakipTalebiEntity) => {
      takipTalebi.alacaklar.forEach((alacak: AlacakEntity) => {
        alacak.alacak_kalemleri.forEach((alacakKalemi: AlacakKalemiEntity) => {
          if (alacakKalemi.alacak_kalemi_alani.takip_oncesi_mi) {
            this.takipToplami += alacakKalemi.tahsil_edilmeyen_tutar * alacakKalemi.kur;
          }
        })
      })
    })
  }

  toplamTahsilHarciTutariHesapla() {
    this.odenenTahsilHarciTutari = 0;
    this.odenecekToplamTahsilHarci = 0;
    this.item.talepler.forEach((takipTalebi: TakipTalebiEntity) => {
      takipTalebi.alacaklar.forEach((alacak: AlacakEntity) => {
        alacak.alacak_kalemleri.forEach((alacakKalemi: AlacakKalemiEntity) => {
          if (!alacakKalemi.alacak_kalemi_alani.takip_oncesi_mi) {
            if (alacakKalemi.alacak_kalemi_alani_id == AlacakKalemiAlani.harc && alacakKalemi.harc_turu_id == this.HarcTuru.tahsil_harci) {
              if (!alacakKalemi.tahsilat_id) {
                this.odenecekToplamTahsilHarci += Number(alacakKalemi.tahsil_edilmeyen_tutar);
              } else {
                this.odenenTahsilHarciTutari += Number(alacakKalemi.tahsil_edilmeyen_tutar);
              }
            }
          }
        })
      })
    })
  }
}
